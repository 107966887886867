.app {
  position: relative;
}

.page {
  position: relative;
  padding: 10px 0;
  max-width: 1000px;
  min-height: 950px;
  left: 50%;
  transform: translateX(-50%);
}