* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: rgb(250, 250, 250);
  min-width: 320px;
}