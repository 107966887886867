.container.projects {
  h2 {
    font-size: 20px;
    text-align: center;
  }

  .featured-projects {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    .project-items {
      max-width: 975px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 25px;
    }
  }

  .other-projects {
    h2 {
      margin-top: 50px;
      padding-bottom: 20px;
    }

    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
}