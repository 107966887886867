.project-item {
  border: 1px solid black;
  border-radius: 10px;
  padding-bottom: 5px;

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid black;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  h4,
  p {
    margin-left: 8px;
  }

  h4 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
  }
}

.project-item:hover {
  background-color: rgb(220, 220, 220);
}

.project-item.featured {
  width: 300px;
}

.project-item.other {
  width: 100%;
}