header {
  position: relative;
  width: 100%;
  height: 50px;
  background-color: white;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  a {
    text-decoration: none;
    color: inherit;
  }

  .logo {
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
  }

  .menu-icon {
    font-size: 30px;
    cursor: pointer;
  }
}