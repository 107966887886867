footer {
  position: relative;
  width: 100%;
  height: 75px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  a {
    color: inherit;
  }

  .social-icons {
    display: flex;
    gap: 15px;
    font-size: 20px;
  }

  .linkedin-icon, .github-icon {
    cursor: pointer;
  }

  .copyright {
    font-size: 10px;
  }
}