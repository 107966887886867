.menu {
  position: absolute;
  left: 0;
  width: 100%;

  ul {
    list-style: none;

    a {
      text-decoration: none;
      color: inherit;
    }

    li {
      text-align: center;
      padding: 20px 0;
      background-color: white;
      border-bottom: 1px solid grey;
      cursor: pointer;
    }

    .first-list-item {
      border-top: 1px solid grey;
    }

    li:hover {
      background-color: rgb(250, 250, 250);
    }
  }
}

.menu.show {
  transform: translateY(0px);
  transition: transform 0.3s;
  z-index: 1;
}

.menu.hide {
  transform: translateY(-243px);
  transition: transform 0.3s;
  z-index: 1;
}