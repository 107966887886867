.container.contact {
  text-align: center;
  h2 {
    font-size: 20px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}