.container.home-page {
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: -2;
  
  img {
    width: 250px;
  }

  .text-zone {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1, h3, p {
      text-align: center;
    }

    h1, h3 {
      font-size: 25px;
    }
    
    h1 {
      font-weight: 500;
    }
    
    h3 {
      font-weight: 600;
    }

    p {
      margin-top: 10px;
      max-width: 550px;
    }

    button {
      width: 150px;
      height: 50px;
      background-color: lightgrey;
      margin-top: 40px;
      border: none;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }

    button:hover {
      background-color: grey;
    }
  }

  .text-zone.projects {
    h3 {
      font-size: 20px;
      margin-top: 150px;
    }
  }
}
